export const MenuItems = [
    {
        title: 'Manage Profile',
        path: '/ManageProfile',
        className: 'drop-down-link'
    },
    {
        title: 'Log Out',
        path: '',
        className: 'drop-down-link'
    }    
];
